<template>
  <v-container
    fill-height
    fluid
  >
    <v-overlay
      v-if="loading"
      absolute
      :value="loading"
      opacity="1"
      color="white"
      class="full-screen"
      text-xs-center
    >
      <div class="d-flex flex-column justify-center align-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        />
        <img
          src="@/assets/Lavirra_logo_SVG.svg"
          height="28px"
          width="auto"
          class="mt-6"
        >
        <span
          class="mt-4 ml-2"
          :style="`font-size: 15px; color: ${$vuetify.theme.themes.light.primary};`"
        >
          {{ message }}
        </span>
      </div>
    </v-overlay>
    <v-card
      width="100%"
      height="100%"
      flat
      :style="`height: ${($vuetify.breakpoint.height - 210).toString() + 'px'}; overflow-y: auto;`"
    >
      <v-card-text class="mt-4">
        <div class="small-title">
          {{ $t('notifications|notifications') }}
        </div>
        <div
          style="display: flex; align-items: flex-start;"
        >
          {{ $t('expressions|get_e-mail_notifications') }}
          <!-- <v-switch
            v-model="company.emailNotifications"
            color="green"
            :value="true"
            hide-details
            class="mt-n1 mb-1 ml-12"
          /> -->
        </div>
        <v-divider
          class="mt-3"
        />
        <div>
          <div class="mt-4 small-title">
            {{ $t('payments|billing_address') }}
          </div>
          <div>
            {{ $t('actions|change_your_billing_address') }}
            <v-btn
              small
              text
              class="btn-text ml-8"
              @click="expandBillingAdressFields = true"
            >
              {{ $t('actions|change_billing_address') }}
              <v-icon
                small
              >
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
          <v-expand-transition>
            <v-card
              v-show="expandBillingAdressFields"
              flat
              height="100%"
              width="40%"
              class="mt-4"
            >
              <v-card-text>
                <v-text-field
                  v-model="companyData.billingName"
                  dense
                  outlined
                  :label="$t('common|name')"
                />
                <v-text-field
                  v-model="companyData.billingAddress.city"
                  dense
                  outlined
                  :label="$t('clients|city')"
                />
                <v-text-field
                  v-model="companyData.billingAddress.country"
                  dense
                  outlined
                  :label="$t('profile|country')"
                />
                <v-text-field
                  v-model="companyData.billingAddress.line1"
                  dense
                  outlined
                  :label="$t('clients|address')"
                />
                <v-text-field
                  v-model="companyData.billingAddress.line2"
                  dense
                  outlined
                  :label="$t('clients|address')"
                />
                <v-text-field
                  v-model="companyData.billingAddress.postal_code"
                  dense
                  outlined
                  :label="$t('clients|postal_code')"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  x-small
                  outlined
                  @click="expandBillingAdressFields = false"
                >
                  {{ $t('actions|cancel') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  x-small
                  rounded
                  color="primary"
                  min-width="150"
                  @click="changeBillingAddress()"
                >
                  {{ $t('actions|submit') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-expand-transition>
          <v-divider
            class="my-6"
          />
        </div>

        <div>
          <div class="small-title">
            {{ $t('payments|subscription') }}
          </div>
          <div v-if="company.subscriptionStatus === 'trial'">
            {{ $t('payments|actual_payments_status') }}:
            <span style="font-weight: bold;">
              {{ translatePaymentsStatus(company.subscriptionStatus) }} - {{ daysLeft }} {{ $t('common|days_left') }}.
            </span>
          </div>
          <div v-if="company.subscriptionStatus === 'paid'">
            {{ $t('payments|actual_payments_status') }}:
            <span style="font-weight: bold;">
              {{ translatePaymentsStatus(company.subscriptionStatus) }}
            </span>
          </div>
          <div v-if="company.subscriptionStatus === 'unpaid'">
            {{ $t('payments|actual_payments_status') }}:
            <span style="font-weight: bold;">
              {{ translatePaymentsStatus(company.subscriptionStatus) }}
            </span>
            <v-btn
              small
              text
              class="btn-text ml-8"
              @click="payTheCompanySubscription()"
            >
              {{ $t('actions|activate_account') }}
              <v-icon
                small
              >
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
          <div v-if="company.subscriptionStatus === 'paid'">
            {{ $t('payments|stop_payments') }}
            <v-btn
              small
              text
              :disabled="hasPermission"
              class="btn-text ml-8"
              @click="cancelCompanySubscription()"
            >
              {{ $t('actions|cancel') }}
              <v-icon
                small
              >
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
          <v-divider
            class="my-6"
          />
        </div>

        <div class="small-title">
          {{ $t('payments|update_payment_details') }}
        </div>
        <div>
          {{ $t('payments|change_credit_card_details') }}
          <v-btn
            small
            text
            class="btn-text ml-8"
            @click="updatePaymentDetails()"
          >
            {{ $t('actions|change_details') }}
            <v-icon
              small
            >
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </div>
        <v-divider
          class="my-6"
        />
        <div v-if="company.userRoles.adminOwner[0] === account._id">
          <div class="small-title">
            {{ $t('common|deactivation') }}
          </div>
          <div>
            {{ $t('expressions|delete_workspaces_company_groups') }}
            <v-btn
              small
              text
              class="btn-text ml-8"
              @click="expandDeactivationMessage = true"
            >
              {{ $t('company|delete') }}
              <v-icon
                small
              >
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
          <v-expand-transition>
            <v-card
              v-show="expandDeactivationMessage"
              flat
              height="100%"
              width="40%"
              class="mt-4"
            >
              <v-card-text>
                {{ $t('expressions|delete_company_info') }}
                <v-textarea
                  v-model="reason"
                  outlined
                  :placeholder="$t('expressions|reason_for_company_deactivation')"
                  class="mt-3"
                />
              </v-card-text>
              <v-card-actions class="mt-n8">
                <v-btn
                  x-small
                  outlined
                  @click="expandDeactivationMessage = false"
                >
                  {{ $t('actions|cancel') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  x-small
                  rounded
                  color="primary"
                  :loading="loading"
                  min-width="150"
                  @click="deleteCompany(company._id)"
                >
                  {{ $t('actions|delete') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-expand-transition>
        </div>
      </v-card-text>
    </v-card>
    <template>
      <StripeCheckout
        ref="checkoutRef"
        :pk="publishableKey"
        :session-id="sesId"
        @loading="v => loading = v"
      />
    </template>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import autobahn from 'autobahn'
import moment from 'moment'

export default {
  components: {
    StripeCheckout
  },
  data: () => ({
    publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    sesId: null,
    expandDeactivationMessage: false,
    expandBillingAdressFields: false,
    companyData: null,
    loading: false,
    reason: null,
    message: '',
    daysLeft: null
  }),
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    }),
    actualCompany () {
      return this.company
    },
    hasPermission () {
      let rule = true
      if (this.account && this.account.currentUserRole && this.account.currentUserRole !== 'admin') rule = false
      if (this.account && this.account.currentUserRole && this.account.currentUserRole !== 'adminOwner') rule = false
      return rule
    }
  },
  watch: {
    actualCompany: {
      handler (value) {
        this.companyData = value.companyData || {}
      },
      immediate: true
    }
  },
  mounted () {
    let companyCreatedAt = moment(this.company.createdAt)
    let now = moment()
    let days = now.diff(companyCreatedAt, 'days')
    this.daysLeft = 30 - days
  },
  methods: {
    ...mapActions({
      setCompany: 'company/setCompany',
      deleteTheCompany: 'company/deleteTheCompany',
      addToast: 'toasts/addToast'
    }),
    translatePaymentsStatus (input) {
      if (input === 'paid') return this.$t('payments|paid')
      if (input === 'unpaid') return this.$t('payments|unpaid')
      if (input === 'company paid') return this.$t('payments|company_paid')
      if (input === 'trial') return this.$t('payments|trial')
    },
    async changeBillingAddress () {
      return new Promise(async (resolve, reject) => {
        try {
          var connection = new autobahn.Connection({
            url: process.env.VUE_APP_CROSSBAR_PATH,
            realm: process.env.VUE_APP_CROSSBAR_REALM
          })
          connection.onopen = async (session) => {
            const result = await session.call('lavvira.stripe_controller.update_billing_details',
              [
                this.company._id,
                'company',
                this.company.companyData.billingName,
                this.company.companyData.billingAddress
              ])
            if (result === 'success') {
              await this.setCompany()
              this.addToast({
                title: this.$t('expressions|successfully_changed_company_billing_address'),
                color: 'white',
                snackbarColor: 'success'
              })
            } else {
              this.addToast({
                title: this.$t('error|something_went_wrong'),
                color: 'white',
                snackbarColor: 'error'
              })
            }
          }
          connection.open()
        } catch (e) {
          reject(e)
        }
      })
    },
    async cancelCompanySubscription () {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure') + ' ' + this.$t('warning|suspending_payments')
      })
      if (!res) return
      return new Promise(async (resolve, reject) => {
        try {
          var connection = new autobahn.Connection({
            url: process.env.VUE_APP_CROSSBAR_PATH,
            realm: process.env.VUE_APP_CROSSBAR_REALM
          })
          connection.onopen = async (session) => {
            const result = await session.call('lavvira.stripe_controller.cancel_subscription',
              [
                this.company._id,
                'company'
              ])
            if (result === 'success') {
              await this.setCompany()
              this.addToast({
                title: this.$t('payments|successful_payment_cancelation'),
                color: 'white',
                snackbarColor: 'success'
              })
              this.$vuetify.theme.themes.light.primary = '#546E7A'
            } else {
              this.addToast({
                title: this.$t('error|error_cancel_subscription'),
                color: 'white',
                snackbarColor: 'error'
              })
            }
          }
          connection.open()
        } catch (e) {
          reject(e)
        }
      })
    },
    payTheCompanySubscription () {
      this.message = this.$t('message|proceed_to_checkout')
      this.loading = true
      let result = null
      let n = 0
      try {
        var connection = new autobahn.Connection({
          url: process.env.VUE_APP_CROSSBAR_PATH,
          realm: process.env.VUE_APP_CROSSBAR_REALM
        })
        connection.onopen = async (session) => {
          const makeSessionCall = async (account, memberFields) => {
            const _res = await session.call('lavvira.stripe_controller.create_payment_session',
              [
                account.companyId,
                'company',
                memberFields
              ])
            return _res
          }
          // try 5 times to get the sessionId from backend
          while (n <= 5) {
            result = await makeSessionCall(this.account, this.company.seats)
            const parsedRes = JSON.parse(result)
            if (parsedRes && !parsedRes.stripe_error) n = 6
            else n++
          }
          const res = JSON.parse(result)
          if (res && !res.stripe_error) {
            const res = JSON.parse(result)
            this.sesId = res.session_id
            setTimeout(() => {
              this.loading = false
              this.$refs.checkoutRef.redirectToCheckout()
            }, 3000)
          } else {
            this.loading = false
            this.addToast({
              title: this.$t('error|something_went_wrong'),
              color: 'white',
              snackbarColor: 'error'
            })
            this.$router.push('/members')
          }
        }
        connection.onclose = function (reason, details) {
          // handle connection lost
          console.log(reason, details, 'connection.onclose')
        }
        connection.open()
      } catch (e) {
        console.error(e, 'WAMP ERROR')
      }
    },
    async updatePaymentDetails () {
      let result = null
      let n = 0
      this.message = this.$t('loading|please_wait')
      this.loading = true
      try {
        var connection = new autobahn.Connection({
          url: process.env.VUE_APP_CROSSBAR_PATH,
          realm: process.env.VUE_APP_CROSSBAR_REALM
        })
        connection.onopen = async (session) => {
          const makeSessionCall = async (company) => {
            const _res = await session.call('lavvira.stripe_controller.update_payment_details',
              [
                company._id,
                'company'
              ])
            return _res
          }
          // try 5 times to get the sessionId from backend
          while (n <= 5) {
            result = await makeSessionCall(this.company)
            const parsedRes = JSON.parse(result)
            if (parsedRes && !parsedRes.stripe_error) n = 6
            else n++
          }
          const res = JSON.parse(result)
          if (res && !res.stripe_error) {
            const res = JSON.parse(result)
            this.sesId = res.session_id
            setTimeout(() => {
              this.loading = false
              this.$refs.checkoutRef.redirectToCheckout()
            }, 3000)
          } else {
            this.loading = false
            this.addToast({
              title: this.$t('error|something_went_wrong'),
              color: 'white',
              snackbarColor: 'error'
            })
            // this.$router.push('/members')
          }
        }
        connection.onclose = function (reason, details) {
          // handle connection lost
          console.log(reason, details, 'connection.onclose')
        }
        connection.open()
      } catch (e) {
        console.error(e, 'WAMP ERROR')
        this.loading = false
      }
    },
    async deleteCompany (_id) {
      let res2
      const res = await this.$dialog.confirm({
        text: this.$t('company|delete_are_you_sure1'),
        title: this.$t('common|warning')
      })
      if (res) {
        res2 = await this.$dialog.confirm({
          text: this.$t('company|delete_are_you_sure2'),
          title: this.$t('common|warning')
        })
      }
      if (res2) {
        const payload = {
          reason: this.reason
        }
        try {
          this.loading = true
          await this.deleteTheCompany({ _id, payload })
          this.loading = false
        } catch (e) {
          this.loading = false
          this.addToast({
            title: this.$t('error|something_went_wrong'),
            color: 'white',
            snackbarColor: 'error'
          })
          console.error(e, 'error')
        }
      }
    }
  }
}
</script>
